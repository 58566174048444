/* http://meyerweb.com/eric/tools/css/reset/ 
   v2.0 | 20110126
   License: none (public domain)
*/

html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
}
/* HTML5 display-role reset for older browsers */
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block;
}
body {
  line-height: 1;
}
ol,
ul {
  list-style: none;
}
blockquote,
q {
  quotes: none;
}
blockquote:before,
blockquote:after,
q:before,
q:after {
  content: "";
  content: none;
}
table {
  border-collapse: collapse;
  border-spacing: 0;
}

/**
 * @license
 * MyFonts Webfont Build ID 3614215, 2018-07-23T16:39:39-0400
 * 
 * The fonts listed in this notice are subject to the End User License
 * Agreement(s) entered into by the website owner. All other parties are 
 * explicitly restricted from using the Licensed Webfonts(s).
 * 
 * You may obtain a valid license at the URLs below.
 * 
 * Webfont: FrutigerLTPro-Light by Linotype
 * URL: https://www.myfonts.com/fonts/linotype/frutiger/frutiger-pro-45-light/
 * 
 * Webfont: FrutigerLTPro-LightCn by Linotype
 * URL: https://www.myfonts.com/fonts/linotype/frutiger/pro-47-light-condensed/
 * 
 * 
 * License: https://www.myfonts.com/viewlicense?type=web&buildid=3614215
 * Licensed pageviews: 15
 * Webfonts copyright: Copyright &#x00A9; 2014 - 2016 Monotype Imaging Inc. All rights reserved.
 * 
 * © 2018 MyFonts Inc
*/

@font-face {
  font-family: "FrutigerLTPro-Light";
  src: url("webfonts/372607_0_0.eot");
  src: url("webfonts/372607_0_0.eot?#iefix") format("embedded-opentype"),
    url("webfonts/372607_0_0.woff2") format("woff2"),
    url("webfonts/372607_0_0.woff") format("woff"),
    url("webfonts/372607_0_0.ttf") format("truetype");
}

@font-face {
  font-family: "FrutigerLTPro-LightCn";
  src: url("webfonts/372607_1_0.eot");
  src: url("webfonts/372607_1_0.eot?#iefix") format("embedded-opentype"),
    url("webfonts/372607_1_0.woff2") format("woff2"),
    url("webfonts/372607_1_0.woff") format("woff"),
    url("webfonts/372607_1_0.ttf") format("truetype");
}

/* Global */
* {
  box-sizing: border-box;
}

html,
body,
#root {
  width: 100%;
  height: auto;
  margin: 0;
  background: white;
}

/* Tablet Landscape ( + ) */
@media only screen and (min-width: 992px) {
  html,
  body,
  #root {
    height: 100vh;
    background: #ededed;
  }
}

html {
  -ms-overflow-style: -ms-autohiding-scrollbar;
}

body {
  font-family: "FrutigerLTPro-Light", Courier, "Lucida Grande",
    "Lucida Sans Unicode", "Lucida Sans", Geneva, Verdana, sans-serif;
  font-size: 14px;
  line-height: 1.4em;
  background: #fff;
  color: #000;
  overscroll-behavior: none;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

/* Phone Landscape ( + ) */
@media only screen and (min-width: 480px) {
  body {
    font-size: 16px;
  }
}

/* Tablet Portrait ( + ) */
@media only screen and (min-width: 768px) {
  body {
    font-size: 18px;
  }
}

/* Tablet Landscape ( + ) */
@media only screen and (min-width: 992px) {
  body {
    font-size: 20px;
  }
}

/* Laptop ( + ) */
@media only screen and (min-width: 1280px) and (min-height: 700px) {
  body {
    font-size: 22px;
  }
}

/* Monitor ( + ) */
@media only screen and (min-width: 1440px) and (min-height: 700px) {
  body {
    font-size: 24px;
  }
}

a {
  font-family: "FrutigerLTPro-LightCn", Courier, "Lucida Grande",
    "Lucida Sans Unicode", "Lucida Sans", Geneva, Verdana, sans-serif;
  text-decoration: none;
  color: #000;
}

h1 {
  font-size: 1.8em;
  line-height: 1.25em;
  padding-bottom: 0.25em;
}

button {
  border: none;
  outline: none;
  padding: 0;
  font-family: "FrutigerLTPro-LightCn", Courier, "Lucida Grande",
    "Lucida Sans Unicode", "Lucida Sans", Geneva, Verdana, sans-serif;
  cursor: pointer;
}

#root {
  display: flex;
}

.screen {
  width: 100%;
  min-height: 100vh;
  margin: 0 auto;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  border: 0;
  padding: 24px;
  background: white;
  border: 24px solid #ededed;
}

/* Tablet Landscape ( + ) */
/* @media only screen and (min-width: 992px) {
  .screen {
    border: 24px solid #ededed;
  }
} */

.screen_content {
  display: none;
}

/* Phone Landscape ( + ) */
@media only screen and (min-width: 480px) {
  .screen_content {
    display: flex;
    width: 100%;
    height: 100%;
    margin: 0 auto;
    flex-direction: column;
    justify-content: flex-start;
    overflow-y: auto;
    overflow-x: hidden;
  }
}

.screen_fallback {
  display: inline-block;
  width: 100%;
  height: 100%;
}

/* Phone Landscape ( + ) */
@media only screen and (min-width: 480px) {
  .screen_fallback {
    display: none;
  }
}

.rotate-message {
  width: 100%;
}

.content_wrapper {
  justify-content: center;
  display: flex;
  flex-grow: 0;
  flex-direction: column;
  margin-bottom: 30px;
}

.content_wrapper--landing {
  flex-grow: 1;
}

/* Global Header */

.global_header {
  margin: 0 0 24px;
}

/*.global_header:after {
  content: "";
  display: table;
  clear: both;
}*/

/* Header */

.header_wrapper {
}

.header {
  padding-bottom: 0.25em;
  line-height: 1.25em;
}

/* Scenario Header */

.scenario_header {
  margin-bottom: 0;
}

/* Laptop ( + ) */
@media only screen and (min-width: 1280px) and (min-height: 700px) {
  .scenario_header {
    margin-bottom: 20px;
  }
}

.scenario_title {
  display: inline-block;
  font-size: 0.8em;
}

.scenario_title::before {
  content: "";
  display: inline-block;
  width: 28px;
  height: 20px;
  vertical-align: middle;
  margin-right: 2px;
  background: url(img/ui-v4.png) no-repeat -0px -44px;
  background: url(img/ui-v4.svg) no-repeat -0px -44px, none;
  transform: scale(0.6);
}

/* Tablet Portrait ( + ) */
@media only screen and (min-width: 768px) {
  .scenario_title::before {
    transform: scale(0.7);
    margin-top: -2px;
    margin-right: 5px;
  }
}

/* Tablet Portrait ( + ) */
@media only screen and (min-width: 992px) {
  .scenario_title::before {
    transform: scale(1);
    margin-top: -3px;
    margin-right: 10px;
  }
}

.scenario_title::after {
  content: "";
  display: inline-block;
  width: 28px;
  height: 20px;
  vertical-align: middle;
  margin-left: 2px;
  background: url(img/ui-v4.png) no-repeat -30px -44px;
  background: url(img/ui-v4.svg) no-repeat -30px -44px, none;
  transform: scale(0.6);
}

/* Tablet Portrait ( + ) */
@media only screen and (min-width: 768px) {
  .scenario_title::after {
    transform: scale(0.7);
    margin-top: -2px;
    margin-left: 5px;
  }
}

/* Tablet Portrait ( + ) */
@media only screen and (min-width: 992px) {
  .scenario_title::after {
    transform: scale(1);
    margin-top: -3px;
    margin-left: 10px;
  }
}

.scenario_stepper {
  float: right;
  display: flex;
}

.scenario_stepper_button {
  display: block;
  padding: 5px 8px 3px;
  margin: 0 0 0 8px;
  text-align: center;
  text-decoration: none;
  border: 1px solid #000;
  color: #000;
  cursor: pointer;
  font-size: 0.75em;
  line-height: 100%;
}

.scenario_stepper_button:hover {
  border: 1px solid #777;
  color: #777;
}

.scenario_stepper_button:first-child {
  margin: 0;
}

.scenario_stepper_button--active,
.scenario_stepper_button--active:hover {
  background: #777;
  border-color: #777;
  color: #fff;
}

/* Landing Headline*/

.content_wrapper--landing .quiz_headline {
  font-size: 2.4em;
  line-height: 1.2em;
  padding-bottom: 0;
}

.content_wrapper--landing .quiz_headline strong {
  display: inline-block;
  width: 100%;
  font-size: 2em;
  line-height: 100%;
  color: #90433b;
}

.content_wrapper--landing .body_copy {
  font-size: 1.2em;
  line-height: 1.4em;
}

/* Quiz Headline */

.quiz_headline_wrapper {
  margin-bottom: 24px;
}

.quiz_headline {
  padding-bottom: 0;
}

/* Advance Button */

.advance_button_wrapper {
  padding: 0;
  display: flex;
  flex-direction: row-reverse;
  align-items: flex-end;
  flex-grow: 0;
}

.advance_button_wrapper a:not(.play_now) {
  position: relative;
  padding: 0 1.6em 0 0;
}

.play_now_wrapper {
  margin-top: 0;
}

.advance_button_wrapper:after {
  content: "";
  display: table;
  clear: both;
}

.play_now {
  background: #8aa75d;
  color: #fff;
  text-align: center;
  padding: 1em 0.9em 1em 1.1em;
  line-height: 1em;
  font-size: 1em;
}

.arrow-right {
  width: 0;
  height: 0;
  border-top: 1.5em solid transparent;
  border-bottom: 1.5em solid transparent;
  border-left: 1.5em solid #8aa75d;
}

/* Body Copy */

.body_copy_wrapper {
  padding: 0;
  max-width: 1200px;
}

.body_copy {
  font-weight: 400;
  padding-bottom: 1em;
  color: #4d4d4d;
}

.body_copy:last-child {
  padding-bottom: 0;
}

.legal_copy_wrapper {
  justify-content: flex-start;
  flex-grow: 5;
  display: flex;
  flex-direction: column;
  padding-bottom: 0.5em;
  overflow-y: scroll;
}

.legal_copy_wrapper p.option_copy {
  font-size: 1em;
  margin: 0 0 0.75em;
  color: black;
  line-height: 1.4em;
  padding: 0;
}

@media only screen and (min-width: 480px) {
  .legal_copy_wrapper p.option_copy {
    font-size: 0.6em;
  }
}

@media only screen and (min-width: 768px) {
  .legal_copy_wrapper p.option_copy {
    font-size: 0.7em;
  }
}

@media only screen and (min-width: 992px) {
  .legal_copy_wrapper p.option_copy {
    font-size: 0.8em;
  }
}

@media only screen and (min-width: 1280px) and (min-height: 700px) {
  .legal_copy_wrapper p.option_copy {
    font-size: 0.9em;
  }
}

.back_button {
  width: 100%;
  background: #8aa75d;
  text-align: center;
  margin: 1.4em 0 0;
  color: #fff;
  padding: 8px 0;
}

/* Options */

.options {
  display: flex;
  margin: 0 24px;
  flex-wrap: wrap;
  justify-content: space-around;
}

.options--result {
  max-width: 80vw;
  margin: 0;
  justify-content: flex-start;
}

.option {
  flex-grow: 1;
  height: 100%;
  min-width: 100px;
  width: 33%;
  padding: 0px;
  transition: all 0.3s ease;
}

.option_headline {
  font-family: "FrutigerLTPro-Light", Courier, "Lucida Grande",
    "Lucida Sans Unicode", "Lucida Sans", Geneva, Verdana, sans-serif;
  font-size: 1.4em;
  line-height: 1.2em;
  margin: 0;
}

.option_copy {
  position: relative;
  margin: 20px 0 0;
  padding-right: 0.8em;
}

/* Phone Landscape ( + ) */
@media only screen and (min-width: 480px) {
  .option_copy {
    padding-right: 1em;
  }
}

/* Tablet Portrait ( + ) */
@media only screen and (min-width: 768px) {
  .option_copy {
    padding-right: 1.5em;
  }
}

/* Tablet Landscape ( + ) */
@media only screen and (min-width: 992px) {
  .option_copy {
    padding-right: 1.75em;
  }
}

/* Laptop ( + ) */
@media only screen and (min-width: 1280px) and (min-height: 700px) {
  .option_copy {
    padding-right: 2em;
  }
}

.option_copy:first-child {
  margin: 0;
}

.option_headline + .option_copy {
  margin: 0.35em 0 0;
}

.option_image {
  min-height: 150px;
  max-width: 12vw;
}

.screen--complete .option_image {
  min-height: 65px;
}

.option_image.image-calendar {
  min-height: 0;
  max-width: 100%;
  margin: 0 0 10px;
}

.option_image.image-phone {
  min-height: 0;
  max-width: 100%;
  margin: 20px 0 10px;
}

/* Laptop ( + ) */
@media only screen and (min-width: 1280px) and (min-height: 700px) {
  .screen--complete .options--result {
    width: 80%;
  }

  .screen--complete .option_image {
    min-height: 120px;
  }
}

.option--button {
  padding: 0px;
}

/* Laptop ( + ) */
@media only screen and (min-width: 1280px) and (min-height: 700px) {
  .option--button {
    padding: 30px;
  }
}

.option--button:hover {
  cursor: pointer;
}

.option--button .character {
  opacity: 0.8;
}

.option--button:hover .character {
  transform: scale(1.15);
  opacity: 1;
}

/* Tablet Portrait ( + ) */
@media only screen and (min-width: 768px) {
  .options--scenario .option--button {
    padding: 20px;
  }
}

.option--schedule {
  border: 1px solid #ccc;
  margin-right: 1em;
  padding: 1em;
  max-width: 400px;
}

.option--phone {
  border: 1px solid #ccc;
  padding: 1em;
  max-width: 400px;
}

.option--phone .option_copy {
  font-family: "FrutigerLTPro-LightCn", Courier, "Lucida Grande",
    "Lucida Sans Unicode", "Lucida Sans", Geneva, Verdana, sans-serif;
  margin: 0;
  line-height: 1.42em;
}

.option--schedule .option_copy {
  margin: 0;
}

.option--completed {
  position: relative;
}

.option--completed .option_image {
  opacity: 0.25;
}

.option--completed .option_headline,
.option--completed .option_copy {
  padding-left: 80px;
}

.options--scenario .option,
.options--character .option {
  width: 25%;
}

.option--completed .image-path-complete {
  position: absolute;
  left: 10px;
  top: 172px;
  z-index: 9999;
}

.options--character .option--button {
  text-align: center;
}

.options--scenario .character-4,
.options--scenario .character-2 {
  transform: scaleX(-1);
  filter: FlipH;
}

.ui-logo {
  display: inline-block;
  text-indent: -9999999px;
  width: 115px;
  height: 42px;
  background: url(img/ui-v4.png) no-repeat -0px -0px;
  background: url(img/ui-v4.svg) no-repeat -0px -0px, none;
}

.ui-close {
  float: right;
  display: inline-block;
  text-indent: -9999999px;
  width: 26px;
  height: 26px;
  background: url(img/ui-v4.png) no-repeat -116px -0px;
  background: url(img/ui-v4.svg) no-repeat -116px -0px, none;
}

.option--button .option_copy:last-child::after {
  content: "";
  display: inline-block;
  width: 10px;
  height: 16px;
  position: absolute;
  bottom: 4px;
  background: url(img/ui-v4.png) no-repeat -144px -0px;
  background: url(img/ui-v4.svg) no-repeat -144px -0px, none;
  margin: 0 0 0 0.2em;
  transition: all 0.3s ease;
  transform: translate(75%, 0) scale(0.75);
}

@media all and (-ms-high-contrast: none) {
  .option--button .option_copy:last-child::after {
    margin: 0 0 -0.1em 0.2em; /* IE10 */
  }

  *::-ms-backdrop,
  .option--button .option_copy:last-child::after {
    margin: 0 0 -0.1em 0.2em;
  } /* IE11 */
}

/* Phone Landscape ( + ) */
@media only screen and (min-width: 480px) {
  .option--button .option_copy:last-child::after {
    transform: translate(75%, 0) scale(0.75);
    bottom: 5px;
  }
}

/* Tablet Portrait ( + ) */
@media only screen and (min-width: 768px) {
  .option--button .option_copy:last-child::after {
    transform: translate(75%, 0) scale(0.85);
    bottom: 6px;
  }
}

/* Tablet Landscape ( + ) */
@media only screen and (min-width: 992px) {
  .option--button .option_copy:last-child::after {
    transform: translate(75%, 0) scale(0.95);
    bottom: 8px;
  }
}

/* Laptop ( + ) */
@media only screen and (min-width: 1280px) and (min-height: 700px) {
  .option--button .option_copy:last-child::after {
    transform: translate(75%, 0) scale(1.05);
    bottom: 11px;
  }
}

.advance_button_wrapper:not(.play_now_wrapper) a::after {
  content: "";
  display: inline-block;
  width: 10px;
  height: 16px;
  position: absolute;
  bottom: 4px;
  background: url(img/ui-v4.png) no-repeat -144px -0px;
  background: url(img/ui-v4.svg) no-repeat -144px -0px, none;
  margin: 0 0 0 0.2em;
  transition: all 0.3s ease;
  transform: translate(75%, 0) scale(0.75);
}

@media all and (-ms-high-contrast: none) {
  .advance_button_wrapper:not(.play_now_wrapper) a::after {
    margin: 0 0 -0.1em 0.2em; /* IE10 */
  }

  *::-ms-backdrop,
  .advance_button_wrapper:not(.play_now_wrapper) a::after {
    margin: 0 0 -0.1em 0.2em;
  } /* IE11 */
}

/* Phone Landscape ( + ) */
@media only screen and (min-width: 480px) {
  .advance_button_wrapper:not(.play_now_wrapper) a::after {
    transform: translate(75%, 0) scale(0.75);
    bottom: 5px;
  }
}

/* Tablet Portrait ( + ) */
@media only screen and (min-width: 768px) {
  .advance_button_wrapper:not(.play_now_wrapper) a::after {
    transform: translate(85%, 0) scale(0.85);
    bottom: 6px;
  }
}

/* Tablet Landscape ( + ) */
@media only screen and (min-width: 992px) {
  .advance_button_wrapper:not(.play_now_wrapper) a::after {
    transform: translate(95%, 0) scale(0.95);
    bottom: 8px;
  }
}

/* Laptop ( + ) */
@media only screen and (min-width: 1280px) and (min-height: 700px) {
  .advance_button_wrapper:not(.play_now_wrapper) a::after {
    transform: translate(105%, 0) scale(1.05);
    bottom: 11px;
  }
}

@keyframes bounce {
  0% {
    transform: scale(0.75) translate(75%, 0);
  }
  100% {
    transform: scale(0.75) translate(175%, 0);
  }
}

@keyframes bounce-phone-landscape {
  0% {
    transform: scale(0.75) translate(75%, 0);
  }
  100% {
    transform: scale(0.75) translate(175%, 0);
  }
}

@keyframes bounce-tablet-portrait {
  0% {
    transform: scale(0.85) translate(85%, 0);
  }
  100% {
    transform: scale(0.85) translate(185%, 0);
  }
}

@keyframes bounce-tablet-landscape {
  0% {
    transform: scale(0.95) translate(95%, 0);
  }
  100% {
    transform: scale(0.95) translate(195%, 0);
  }
}

@keyframes bounce-laptop {
  0% {
    transform: scale(1.05) translate(105%, 0);
  }
  100% {
    transform: scale(1.05) translate(205%, 0);
  }
}

.option--button:hover .option_copy:last-child::after {
  animation: bounce 0.25s infinite alternate;
}

/* Phone Landscape ( + ) */
@media only screen and (min-width: 480px) {
  .option--button:hover .option_copy:last-child::after {
    animation: bounce-phone-landscape 0.25s infinite alternate;
  }
}

/* Tablet Portrait ( + ) */
@media only screen and (min-width: 768px) {
  .option--button:hover .option_copy:last-child::after {
    animation: bounce-tablet-portrait 0.25s infinite alternate;
  }
}

/* Tablet Landscape ( + ) */
@media only screen and (min-width: 992px) {
  .option--button:hover .option_copy:last-child::after {
    animation: bounce-tablet-landscape 0.25s infinite alternate;
  }
}

/* Laptop ( + ) */
@media only screen and (min-width: 1280px) and (min-height: 700px) {
  .option--button:hover .option_copy:last-child::after {
    animation: bounce-laptop 0.25s infinite alternate;
  }
}

.advance_button_wrapper:not(.play_now_wrapper) a:hover::after {
  animation: bounce 0.25s infinite alternate;
}

/* Phone Landscape ( + ) */
@media only screen and (min-width: 480px) {
  .advance_button_wrapper:not(.play_now_wrapper) a:hover::after {
    animation: bounce-phone-landscape 0.25s infinite alternate;
  }
}

/* Tablet Portrait ( + ) */
@media only screen and (min-width: 768px) {
  .advance_button_wrapper:not(.play_now_wrapper) a:hover::after {
    animation: bounce-tablet-portrait 0.25s infinite alternate;
  }
}

/* Tablet Landscape ( + ) */
@media only screen and (min-width: 992px) {
  .advance_button_wrapper:not(.play_now_wrapper) a:hover::after {
    animation: bounce-tablet-landscape 0.25s infinite alternate;
  }
}

/* Laptop ( + ) */
@media only screen and (min-width: 1280px) and (min-height: 700px) {
  .advance_button_wrapper:not(.play_now_wrapper) a:hover::after {
    animation: bounce-laptop 0.25s infinite alternate;
  }
}

/* Characters */

.character {
  display: inline-block;
  height: 25vw;
  width: 100%;
  text-indent: -999999px;
  max-height: none;
  transition: all 0.3s ease;
  margin-bottom: 0;
}

/* Tablet Landscape ( + ) */
@media only screen and (min-width: 768px) {
  .character {
    height: 38vh;
  }
}

/* Tablet Landscape ( + ) */
@media only screen and (min-width: 992px) {
  .character {
    height: 45vh;
  }
}

.option.character {
  margin-top: 24px;
}

.character-1 {
  background: url("img/character-1@2x.png") no-repeat center center;
  background: url("img/character-1.svg") no-repeat center center, none;
  background-size: auto 100%;
}

.character-2 {
  background: url("img/character-2@2x.png") no-repeat center center;
  background: url("img/character-2.svg") no-repeat center center, none;
  background-size: auto 100%;
}

.character-3 {
  background: url("img/character-3@2x.png") no-repeat center center;
  background: url("img/character-3.svg") no-repeat center center, none;
  background-size: auto 100%;
}

.character-4 {
  background: url("img/character-4@2x.png") no-repeat center center;
  background: url("img/character-4.svg") no-repeat center center, none;
  background-size: auto 100%;
}

.character-illustration-wrapper {
  position: relative;
  background: transparent;
  padding: 0 20px 0 10px;
}

/* Tablet Portrait ( + ) */
@media only screen and (min-width: 768px) {
  .character-illustration-wrapper {
    padding: 0;
  }
}

.character-animated {
  display: inline-block;
  height: 100%;
  max-height: 25vw;
  max-width: 100%;
  overflow: visible;
  position: relative;
  z-index: 10;
  width: 40%;
}

.character-animated--airplane {
  width: 80%;
  margin-left: 20%;
}

/* Tablet Portrait ( + ) */
@media only screen and (min-width: 768px) {
  .character-animated {
    max-height: 26vw;
  }
}

/* Tablet Landscape ( + ) */
@media only screen and (min-width: 992px) {
  .character-animated {
    max-height: 28vw;
  }
}

/* Laptop ( + ) */
@media only screen and (min-width: 1280px) and (min-height: 700px) {
  .character-animated {
    max-height: 29vw;
    height: 100%;
  }
}

@keyframes characterLift {
  0% {
    transform: rotate(-0deg);
  }
  100% {
    transform: rotate(-20deg);
  }
}

@keyframes throwPlane {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(36deg);
  }
}

@keyframes glidePlane {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes hidePlane {
  0% {
    opacity: 100;
  }
  100% {
    opacity: 0;
  }
}

#character-1:not(.character-animated--airplane) #arm {
  animation: characterLift 0.75s infinite alternate
    cubic-bezier(0.895, 0.03, 0.685, 0.22);
  transform-origin: 145px 120px;
}

#character-2:not(.character-animated--airplane) #arm {
  animation: characterLift 0.75s infinite alternate
    cubic-bezier(0.895, 0.03, 0.685, 0.22);
  transform-origin: 115px 115px;
}

#character-3:not(.character-animated--airplane) #arm {
  animation: characterLift 0.75s infinite alternate
    cubic-bezier(0.895, 0.03, 0.685, 0.22);
  transform-origin: 111px 128px;
}

#character-4:not(.character-animated--airplane) #arm {
  animation: characterLift 0.75s infinite alternate
    cubic-bezier(0.895, 0.03, 0.685, 0.22);
  transform-origin: 100px 100px;
}

#character-1.character-animated--airplane #arm_and_plane {
  animation: throwPlane 1s 2 alternate cubic-bezier(0.68, -0.55, 0.265, 1.55);
  transform-origin: 300px 100px;
}

#character-2.character-animated--airplane #arm_and_plane {
  animation: throwPlane 1s 2 alternate cubic-bezier(0.68, -0.55, 0.265, 1.55);
  transform-origin: 332px 118px;
}

#character-3.character-animated--airplane #arm_and_plane {
  animation: throwPlane 1s 2 alternate cubic-bezier(0.68, -0.55, 0.265, 1.55);
  transform-origin: 330px 126px;
}

#character-4.character-animated--airplane #arm_and_plane {
  animation: throwPlane 1s 2 alternate cubic-bezier(0.68, -0.55, 0.265, 1.55);
  transform-origin: 340px 110px;
}

.character-animated--airplane #glide {
  opacity: 0;
  animation: glidePlane 0s 1.5s 1 forwards;
}

.character-animated--airplane #plane {
  opacity: 100;
  animation: hidePlane 0s 1.45s 1 forwards;
}

.png.character-admiring-award #character-1 {
  display: inline-block;
  background: url("img/character-1-award.png") no-repeat center center;
  background-size: contain;
}

.png.character-water-cooler #character-1 {
  display: inline-block;
  background: url("img/character-1-wand.png") no-repeat center center;
  background-size: contain;
}

.png.character-airplane #character-1 {
  display: inline-block;
  background: url("img/character-1-airplane.png") no-repeat center center;
  background-size: contain;
}

.png.character-mail #character-1 {
  display: inline-block;
  background: url("img/character-1-mail.png") no-repeat center center;
  background-size: contain;
}

.png.character-box #character-1,
.png.character-kitchen #character-1,
.png.character-mailbox #character-1,
.png.character-party #character-1,
.png.character-pool #character-1 {
  display: inline-block;
  background: url("img/character-1-generic.png") no-repeat center center;
  background-size: contain;
}

.png.character-admiring-award #character-2 {
  display: inline-block;
  background: url("img/character-2-award.png") no-repeat center center;
  background-size: contain;
}

.png.character-water-cooler #character-2 {
  display: inline-block;
  background: url("img/character-2-wand.png") no-repeat center center;
  background-size: contain;
}

.png.character-airplane #character-2 {
  display: inline-block;
  background: url("img/character-2-airplane.png") no-repeat center center;
  background-size: contain;
}

.png.character-mail #character-2 {
  display: inline-block;
  background: url("img/character-2-mail.png") no-repeat center center;
  background-size: contain;
}

.png.character-box #character-2,
.png.character-kitchen #character-2,
.png.character-mail #character-2,
.png.character-mailbox #character-2,
.png.character-party #character-2,
.png.character-pool #character-2 {
  display: inline-block;
  background: url("img/character-2-generic.png") no-repeat center center;
  background-size: contain;
}

.png.character-admiring-award #character-3 {
  display: inline-block;
  background: url("img/character-3-award.png") no-repeat center center;
  background-size: contain;
}

.png.character-water-cooler #character-3 {
  display: inline-block;
  background: url("img/character-3-wand.png") no-repeat center center;
  background-size: contain;
}

.png.character-airplane #character-3 {
  display: inline-block;
  background: url("img/character-3-airplane.png") no-repeat center center;
  background-size: contain;
}

.png.character-mail #character-3 {
  display: inline-block;
  background: url("img/character-3-mail.png") no-repeat center center;
  background-size: contain;
}

.png.character-box #character-3,
.png.character-kitchen #character-3,
.png.character-mail #character-3,
.png.character-mailbox #character-3,
.png.character-party #character-3,
.png.character-pool #character-3 {
  display: inline-block;
  background: url("img/character-3-generic.png") no-repeat center center;
  background-size: contain;
}

.png.character-admiring-award #character-4 {
  display: inline-block;
  background: url("img/character-4-award.png") no-repeat center center;
  background-size: contain;
}

.png.character-water-cooler #character-4 {
  display: inline-block;
  background: url("img/character-4-wand.png") no-repeat center center;
  background-size: contain;
}

.png.character-airplane #character-4 {
  display: inline-block;
  background: url("img/character-4-airplane.png") no-repeat center center;
  background-size: contain;
}

.png.character-mail #character-4 {
  display: inline-block;
  background: url("img/character-4-mail.png") no-repeat center center;
  background-size: contain;
}

.png.character-box #character-4,
.png.character-kitchen #character-4,
.png.character-mail #character-4,
.png.character-mailbox #character-4,
.png.character-party #character-4,
.png.character-pool #character-4 {
  display: inline-block;
  background: url("img/character-4-generic.png") no-repeat center center;
  background-size: contain;
}

.character-kitchen .character-animated,
.character-mail .character-animated {
  transform: scaleX(-1) translateX(-125%);
  margin-right: 0;
  margin-left: 0;
}

/* Tablet Portrait ( + ) */
/*@media only screen and ( min-width: 768px ) {
	.character-airplane .character-animated,
	.character-kitchen .character-animated,
	.character-mail .character-animated {
		margin-right: 20px;
	}
}*/

/* Laptop ( + ) */
/*@media only screen and ( min-width: 1280px ) {
	.character-airplane .character-animated,
	.character-kitchen .character-animated,
	.character-mail .character-animated {
		margin-right: 30px;
	}
}
*/
/* Character Backgrounds */

.character-background {
  display: inline-block;
  position: absolute;
  width: calc(65%);
  height: 100%;
  z-index: 1;
  right: 20px;
  top: 0;
  opacity: 0.75;
}

/* Tablet Portrait ( + ) */
@media only screen and (min-width: 768px) {
  .character-background {
    width: calc(68%);
    right: 20px;
    opacity: 1;
  }
}

/* Laptop ( + ) */
@media only screen and (min-width: 1280px) and (min-height: 700px) {
  .character-background {
    width: calc(70%);
    right: 30px;
  }
}

.character-airplane .character-background,
.character-kitchen .character-background,
.character-mail .character-background {
  right: auto;
  left: 0;
}

.character-admiring-award-background {
  background-size: contain;
  background: url("img/character-admiring-award-background@2x.png") no-repeat
    center center;
  background: url("img/character-admiring-award-background.svg") no-repeat
      center center,
    none;
}

.character-airplane-background {
  background-size: contain;
  background: url("img/character-airplane-background@2x.png") no-repeat center
    center;
  background: url("img/character-airplane-background.svg") no-repeat center
      center,
    none;
}

.character-box-background {
  background-size: contain;
  background: url("img/character-box-background@2x.png") no-repeat center center;
  background: url("img/character-box-background.svg") no-repeat center center,
    none;
}

.character-kitchen-background {
  background-size: contain;
  background: url("img/character-kitchen-background@2x.png") no-repeat center
    center;
  background: url("img/character-kitchen-background.svg") no-repeat center
      center,
    none;
}

.character-mail-background {
  background-size: contain;
  background: url("img/character-mail-background@2x.png") no-repeat center
    center;
  background: url("img/character-mail-background.svg") no-repeat center center,
    none;
}

.character-mailbox-background {
  background-size: contain;
  background: url("img/character-mailbox-background@2x.png") no-repeat center
    center;
  background: url("img/character-mailbox-background.svg") no-repeat center
      center,
    none;
}

.character-party-background {
  background-size: contain;
  background: url("img/character-party-background@2x.png") no-repeat center
    center;
  background: url("img/character-party-background.svg") no-repeat center center,
    none;
}

.character-pool-background {
  background-size: contain;
  background: url("img/character-pool-background@2x.png") no-repeat center
    center;
  background: url("img/character-pool-background.svg") no-repeat center center,
    none;
}

.character-water-cooler-background {
  background-size: contain;
  background: url("img/character-water-cooler-background@2x.png") no-repeat
    center center;
  background: url("img/character-water-cooler-background.svg") no-repeat center
      center,
    none;
}

.image {
  display: inline-block;
  width: 100%;
  height: auto;
}

.image-airplane {
  background-size: contain;
  background: url("img/image-airplane@2x.png") no-repeat left center;
  background: url("img/image-airplane.svg") no-repeat left center, none;
}

.image-apples {
  background-size: contain;
  background: url("img/image-apples@2x.png") no-repeat left center;
  background: url("img/image-apples.svg") no-repeat left center, none;
}

.image-award {
  background-size: contain;
  background: url("img/image-award@2x.png") no-repeat left center;
  background: url("img/image-award.svg") no-repeat left center, none;
}

.image-balloons {
  background-size: contain;
  background: url("img/image-balloons@2x.png") no-repeat left center;
  background: url("img/image-balloons.svg") no-repeat left center, none;
}

.image-calculator {
  background-size: contain;
  background: url("img/image-calculator@2x.png") no-repeat left center;
  background: url("img/image-calculator.svg") no-repeat left center, none;
}

.image-calendar {
  width: 94px;
  height: 106px;
  background-size: contain;
  background: url("img/image-calendar@2x.png") no-repeat left center;
  background: url("img/image-calendar.svg") no-repeat left center, none;
}

.image-champagne {
  background-size: contain;
  background: url("img/image-champagne@2x.png") no-repeat left center;
  background: url("img/image-champagne.svg") no-repeat left center, none;
}

.image-chart {
  background-size: contain;
  background: url("img/image-chart@2x.png") no-repeat left center;
  background: url("img/image-chart.svg") no-repeat left center, none;
}

.image-chat-bubbles {
  background-size: contain;
  background: url("img/image-chat-bubbles@2x.png") no-repeat left center;
  background: url("img/image-chat-bubbles.svg") no-repeat left center, none;
}

.image-check-mark {
  background-size: contain;
  background: url("img/image-check-mark@2x.png") no-repeat left center;
  background: url("img/image-check-mark.svg") no-repeat left center, none;
}

.image-creditscore {
  background-size: contain;
  background: url("img/image-creditscore@2x.png") no-repeat left center;
  background: url("img/image-creditscore.svg") no-repeat left center, none;
}

.image-faucet {
  background-size: contain;
  background: url("img/image-faucet@2x.png") no-repeat left center;
  background: url("img/image-faucet.svg") no-repeat left center, none;
}

.image-for-sale {
  background-size: contain;
  background: url("img/image-for-sale@2x.png") no-repeat left center;
  background: url("img/image-for-sale.svg") no-repeat left center, none;
}

.image-got-my-game-plan {
  background-size: contain;
  background: url("img/image-got-my-game-plan@2x.png") no-repeat left center;
  background: url("img/image-got-my-game-plan.svg") no-repeat left center, none;
}

.image-home-sweet-home {
  background-size: contain;
  background: url("img/image-home-sweet-home@2x.png") no-repeat left center;
  background: url("img/image-home-sweet-home.svg") no-repeat left center, none;
}

.image-hourglass {
  background-size: contain;
  background: url("img/image-hourglass@2x.png") no-repeat left center;
  background: url("img/image-hourglass.svg") no-repeat left center, none;
}

.image-house {
  background-size: contain;
  background: url("img/image-house@2x.png") no-repeat left center;
  background: url("img/image-house.svg") no-repeat left center, none;
}

.image-loan {
  background-size: contain;
  background: url("img/image-loan@2x.png") no-repeat left center;
  background: url("img/image-loan.svg") no-repeat left center, none;
}

.image-magnifying-glass {
  background-size: contain;
  background: url("img/image-magnifying-glass@2x.png") no-repeat left center;
  background: url("img/image-magnifying-glass.svg") no-repeat left center, none;
}

.image-map {
  background-size: contain;
  background: url("img/image-map@2x.png") no-repeat left center;
  background: url("img/image-map.svg") no-repeat left center, none;
}

.image-party-hats {
  background-size: contain;
  background: url("img/image-party-hats@2x.png") no-repeat left center;
  background: url("img/image-party-hats.svg") no-repeat left center, none;
}

.image-percent {
  background-size: contain;
  background: url("img/image-percent@2x.png") no-repeat left center;
  background: url("img/image-percent.svg") no-repeat left center, none;
}

.image-phone {
  width: 100px;
  height: 84px;
  background-size: contain;
  background: url("img/image-phone@2x.png") no-repeat left center;
  background: url("img/image-phone.svg") no-repeat left center, none;
}
.image-piggy-bank {
  background-size: contain;
  background: url("img/image-piggy-bank@2x.png") no-repeat left center;
  background: url("img/image-piggy-bank.svg") no-repeat left center, none;
}

.image-price-tags {
  background-size: contain;
  background: url("img/image-price-tags@2x.png") no-repeat left center;
  background: url("img/image-price-tags.svg") no-repeat left center, none;
}

.image-safe-closed {
  background-size: contain;
  background: url("img/image-safe-closed@2x.png") no-repeat left center;
  background: url("img/image-safe-closed.svg") no-repeat left center, none;
}

.image-safe {
  background-size: contain;
  background: url("img/image-safe@2x.png") no-repeat left center;
  background: url("img/image-safe.svg") no-repeat left center, none;
}

.image-suitcase {
  background-size: contain;
  background: url("img/image-suitcase@2x.png") no-repeat left center;
  background: url("img/image-suitcase.svg") no-repeat left center, none;
}

.image-sunglasses {
  background-size: contain;
  background: url("img/image-sunglasses@2x.png") no-repeat left center;
  background: url("img/image-sunglasses.svg") no-repeat left center, none;
}

.image-tickets {
  background-size: contain;
  background: url("img/image-tickets@2x.png") no-repeat left center;
  background: url("img/image-tickets.svg") no-repeat left center, none;
}

.image-trees {
  background-size: contain;
  background: url("img/image-trees@2x.png") no-repeat left center;
  background: url("img/image-trees.svg") no-repeat left center, none;
}

.image-trophy {
  background-size: contain;
  background: url("img/image-trophy@2x.png") no-repeat left center;
  background: url("img/image-trophy.svg") no-repeat left center, none;
}

.image-wallet {
  background-size: contain;
  background: url("img/image-wallet@2x.png") no-repeat left center;
  background: url("img/image-wallet.svg") no-repeat left center, none;
}

.image-watering-flowers {
  background-size: contain;
  background: url("img/image-watering-flowers@2x.png") no-repeat left center;
  background: url("img/image-watering-flowers.svg") no-repeat left center, none;
}

.image-path-complete {
  width: 80px;
  height: 104px;
  background: url("img/image-path-complete@2x.png") no-repeat center center;
  background-size: contain;
}
